const prefix = "misc/";

export const miscTypes = {
  actions: {
    ADD_HEADER_NAV_LINK: prefix + "ADD_HEADER_NAV_LINK",
    REMOVE_HEADER_NAV_LINKS: prefix + "REMOVE_HEADER_NAV_LINKS",
    REMOVE_HEADER_NAV_LINK_BY_LABEL: prefix + "REMOVE_HEADER_NAV_LINK_BY_LABEL",
    TOGGLE_LOADER: prefix + "TOGGLE_LOADER",
  },
  mutations: {
    SET_IS_ONLINE: prefix + "SET_IS_ONLINE",
    SET_PAGE_TITLE: prefix + "SET_PAGE_TITLE",
    SET_HEADER_NAV_LINKS: prefix + "SET_HEADER_NAV_LINKS",
    SET_SHOW_LOADER: prefix + "SET_SHOW_LOADER",
    SET_API_ERRORS: prefix + "SET_API_ERRORS",
  },
};
