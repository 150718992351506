



































import BaseComponent from "@/components/BaseComponent";
import { Component } from "vue-property-decorator";
import { IrmaOrder } from "@/types/irmaOrder.class";
import Modal from "@/components/Modal.vue";
import "vue-select/dist/vue-select.css";
import { Contract } from "@/types/contract.class";
import Handshake from '@/components/svgs/HandshakeIcon.vue';
import Back from '@/components/svgs/BackIcon.vue';

@Component({
  components: {
    Modal,
    Handshake,
    Back
  },
  props: {
    model: String,
  },
})
export default class Users extends BaseComponent {
  data: IrmaOrder[] = [];
  contract: Contract = new Contract();
  fieldsSub: any[] = [
    {
      key: "productGroup",
      label: "Productgroep",
    },
    {
      key: "productType",
      label: "Producttype",
    },
    {
      key: "productName",
      label: "Productnaam",
    },
    {
      key: "characteristic",
      label: "Eigenschappen",
      sortable: true,
    },
  ];

  async mounted(): Promise<void> {
    await this.getData();
  }

  async getData(): Promise<void> {
    const model = new Contract(JSON.parse(this.$props.model));
    this.contract = model;
    this.data = model.irmaOrders;
  }
}
