




















































































import { getDashboard } from "@/api/dashboard";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import { Component } from "vue-property-decorator";
import BaseComponent from "@/components/BaseComponent";
import Modal from "@/components/Modal.vue";
import MyActions from "@/components/MyActions.vue";
import Clipboard from "@/components/svgs/ClipboardIcon.vue";
import Back from "@/components/svgs/BackIcon.vue";
import getEnv from "@/utils/environment/getEnvironment";

@Component({
  components: {
    Modal,
    MyActions,
    Clipboard,
    Back,
  },
})
export default class Root extends BaseComponent {
  data: any = [];
  dataClosed: any = [];
  shownTicket = null;
  fieldsHeader: any[] = [
    {
      key: "title",
      label: "Titel",
    },
    {
      key: "number",
      label: "No.",
      thStyle: { width: "15%" },
    },
    {
      key: "caller.name",
      label: "Ticket aanmelder",
    },
    {
      key: "processingStatus.name",
      label: "Status",
    },
    {
      key: "category.name",
      label: "Categorie",
    },
    {
      key: "actions",
      label: "",
      thClass: "text-right",
      tdClass: "text-right",
    },
  ];

  newTicketUrl() {
    window.open("https://utica.topdesk.net/tas/public/login/saml");
  }

  get ticketJSON(): string {
    return JSON.stringify(this.shownTicket, null, "\t");
  }

  async mounted(): Promise<void> {
    if ((this as any).$auth.isAuthenticated) {
      this.$store.dispatch(miscTypes.actions.TOGGLE_LOADER);
      try {
        const result = (await getDashboard()).data.data;
        result.tickets.forEach((item: any) => {
          if (item.closed || item.completed) {
            this.dataClosed.push(item);
          } else {
            this.data.push(item);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch(miscTypes.actions.TOGGLE_LOADER);
      }
    }
  }

  topdeskUrl(ticket: any): string {
    const cleanTicketId = ticket.item.id.replaceAll('-','')
    return getEnv().topDeskBaseUrl + '/tas/public/ssp/content/detail/incident?unid=' + cleanTicketId
  }

  openTicketModal(ticket: any): void {
    this.shownTicket = ticket;
  }
}
