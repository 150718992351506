import Vue from "vue";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  BaseLoginOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import { vueInstance } from "../vueInstance";
import jwt_decode from 'jwt-decode';

export const sessionCheckExpiryDays = 100;

/** Define a default action to perform after authentication */
// eslint-disable-next-line
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) => {
  setTimeout(() => {
    vueInstance().$router.push({ name: "Root" });
    window.history.replaceState({}, document.title, window.location.pathname);
  }, 500);
};

let instance: any;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = `${window.location.origin}/auth/callback`,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue<any>({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        role: null,
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o: BaseLoginOptions) {
        this.popupOpen = true;

        try {
          await (this.auth0Client as any).loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }
        
        this.user = await (this.auth0Client as any).getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        try {
          await (this.auth0Client as any).handleRedirectCallback();

          this.user = await (this.auth0Client as Auth0Client).getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect() {
        return (this.auth0Client as any).loginWithRedirect({
          redirectUri: `${window.location.origin}/auth/callback`,
        });
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: getIdTokenClaimsOptions) {
        return (this.auth0Client as any).getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o?: GetTokenSilentlyOptions) {
        return (this.auth0Client as any).getTokenSilently(o ?? {});
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o: GetTokenWithPopupOptions) {
        return (this.auth0Client as any).getTokenWithPopup(o);
      },
      // async getRole(): Promise<String> {
      //     const roleKeyName = 'https://schemas.quickstarts.com/roles';

      //     const tokenObj = await this.getIdTokenClaims();
      //     if (!tokenObj) return null;

      //     if (!tokenObj[roleKeyName] || !Array.isArray(tokenObj[roleKeyName])) return null;
      //     if (tokenObj[roleKeyName].length <= 0) {
      //         this.logout();
      //         return null;
      //     }

      //     return (tokenObj[roleKeyName][0] as string).toLowerCase();
      // },
      async getUserId(): Promise<any> {
        return (await this.getIdTokenClaims()).sub;
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o: LogoutOptions = { returnTo: window.location.origin }) {
        return (this.auth0Client as any).logout(o);
      },
      async instanceCreatedCallback() {
        this.auth0Client.checkSession();
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          this.user = await this.auth0Client.getUser();
        }
      },
      checkAuthentication() {
        if (!this.isAuthenticated) {
          // vueInstance().$router.push({ name: 'AuthLogin' });
          this.loginWithRedirect();
        }
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      (this.auth0Client as any) = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await (
            this.auth0Client as any
          ).handleRedirectCallback();
          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        await this.instanceCreatedCallback();
        if (this.isAuthenticated) {
          this.user = await (this.auth0Client as any).getUser();
          this.claims = jwt_decode(await (this.auth0Client as any).getTokenSilently());
          const roles = (this.claims as any)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
          this.role = roles && roles.length > 0 ? roles[0] : null;

          if (this.role == null) {
            console.warn('User has no role specified.');
            this.logout();
          }
         }
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue: any, options: Auth0ClientOptions): any {
    Vue.prototype.$auth = useAuth0(options);
  },
};

export function isAuth0Loaded(): Promise<any> {
  const poll = (resolve: any) => {
    if (getInstance() && !getInstance().loading) {
      resolve();
    } else {
      setTimeout(() => poll(resolve), 400);
    }
  };

  return new Promise(poll);
}
