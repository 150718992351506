import Vue from "vue";
import Vuex, { Store } from "vuex";
import { EnvironmentStateModule } from "./modules/environment/environmentModule.class";
import { MiscStateModule } from "./modules/misc/miscModule.class";
import { IState } from "./state.interface";

Vue.use(Vuex);

export function createStore(): Store<IState> {
  return new Vuex.Store({
    modules: {
      environment: new EnvironmentStateModule(),
      misc: new MiscStateModule(),
    },
  });
}
