import { IState } from "@/store/state.interface";
import { globalVueInstanceVariableName } from "./customWindow";

export const vueInstance = (): Vue => {
  const instance = (window as Window | any)[globalVueInstanceVariableName];

  if (!instance) {
    throw new Error(
      "The Vue instance is either not created yet, or not globally available."
    );
  }

  return instance;
};

export const getState = (): IState => {
  return vueInstance().$store.state;
};
