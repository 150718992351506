








































































































import BaseComponent from "@/components/BaseComponent";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import { Component } from "vue-property-decorator";
import { FiberAvailability } from "@/types/fiberAvailability.class";
import { FiberResponseModel } from "@/types/fiberResponseModel.class";
import { getFiberAvailability } from "@/api/fiberAvailability";
import ToastrHelper from "@/utils/toastr-helper";
import MyActions from "@/components/MyActions.vue";
import Landmark from "@/components/svgs/LandmarkIcon.vue";
import Back from "@/components/svgs/BackIcon.vue";
import { isPostalCode } from "class-validator";

@Component({
  components: {
    MyActions,
    Landmark,
    Back,
  },
})
export default class FiberAvailabilities extends BaseComponent {
  get searchValid(): boolean {
    return (
      isPostalCode(this.postalCode, "NL") && this.houseNumber.trim().length > 0
    );
  }

  postalCode = "";
  houseNumber = "";
  message = "Geen data beschikbaar";
  data: FiberAvailability[] = [];
  fiberResponseModel: FiberResponseModel = new FiberResponseModel();

  async getFiberAvailabilities(): Promise<void> {
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);
    this.data.splice(0, this.data.length);
    try {
      this.fiberResponseModel = (
        await getFiberAvailability(this.postalCode, this.houseNumber)
      ).data.body;
      if (this.fiberResponseModel.fiberAvailability !== null) {
        this.data.push(
          new FiberAvailability(this.fiberResponseModel.fiberAvailability)
        );
      }

      if (this.fiberResponseModel.weasFiberAvailability !== null) {
        this.data.push(
          new FiberAvailability(this.fiberResponseModel.weasFiberAvailability)
        );
      }

      if (this.fiberResponseModel.weasKoperAvailability !== null) {
        this.data.push(
          new FiberAvailability(this.fiberResponseModel.weasKoperAvailability)
        );
      }

      if (this.data.length === 0) {
        ToastrHelper.warningToastr("Geen resultaten gevonden", this);
        this.message = "Helaas zijn er geen beschikbare internetaansluitingen beschikbaar voor uw zoekterm via ons";
      }
    } catch (error) {
      const responseStatus: number = (error as any).response.status;

      this.data = [];
      if (responseStatus === 400) {
        ToastrHelper.errorToastr(
          "Geef een geldige postcode en huisnummer op",
          this
        );
      }
    } finally {
      this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
    }
  }

  goToUrl(fiber: any): string {
    if (fiber.availability === "Green") {
      return "https://www.score-utica.nl/glasvezel-beschikbaar-portal-aanvraag";
    }
    return "https://www.score-utica.nl/glasvezel-bijna-beschikbaar-portal-aanvraag";
  }
}
