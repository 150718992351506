import { IState } from "@/store/state.interface";
import { Module } from "vuex";
import { BaseStoreModule } from "../baseStoreModule.class";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export class EnvironmentStateModule extends BaseStoreModule<
  EnvironmentState,
  IState
> {
  constructor(state?: EnvironmentState) {
    super({
      namespaced: true,
      state: new EnvironmentState(state),
      actions,
      mutations,
      getters,
    });
  }

  getModule(): Module<EnvironmentState, IState> {
    return {
      namespaced: this.namespaced,
      state: this.state,
      actions: this.actions,
      mutations: this.mutations,
      getters: this.getters,
    };
  }
}

export class EnvironmentState {
  apiBaseUrl: string | undefined;
  topDeskBaseUrl: string | undefined;
  oneBaseBaseUrl: string | undefined;
  auth0: IAuth0Environment | undefined;

  constructor(state?: EnvironmentState) {
    this.apiBaseUrl = state?.apiBaseUrl ?? undefined;
    this.topDeskBaseUrl = state?.topDeskBaseUrl ?? undefined;
    this.oneBaseBaseUrl = state?.oneBaseBaseUrl ?? undefined;
    
    this.auth0 = state?.auth0 ?? undefined;
  }
}

interface IAuth0Environment {
  domain: string;
  clientId: string;
  audience: string;
}
