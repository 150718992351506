import Vue from "vue";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import { createStore } from "./store";
import { EnvironmentState } from "./store/modules/environment/environmentModule.class";
import { Auth0Plugin, isAuth0Loaded } from "./utils/auth";
import { initInterceptors } from "./utils/axiosInterceptors";
import { globalVueInstanceVariableName } from "./utils/customWindow";
import LoadEnvironment from "./utils/environment/loadEnvironment";
import { vueInstance } from "./utils/vueInstance";
import "@/scss/styles.scss";
import { environmentTypes } from "./store/modules/environment/environmentTypes";
import { ToastPlugin } from "bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;
Vue.use(ToastPlugin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('v-select', vSelect)
async function init(): Promise<void> {
  let env;
  try {
    const envResult = await LoadEnvironment();
    env = new EnvironmentState(envResult);
  } catch (error) {
    throw Error("Environment could not be loaded.");
  }

  initInterceptors();

  Vue.use(Auth0Plugin, {
    domain: env.auth0?.domain,
    clientId: env.auth0?.clientId,
    audience: env.auth0?.audience,
  });

  await isAuth0Loaded();

  const store = createStore();

  (window as any)[globalVueInstanceVariableName] = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  (vueInstance() as any).$store.commit(
    environmentTypes.mutations.SET_ENVIRONMENT,
    env
  );

  (vueInstance() as any).$auth.checkAuthentication();

  vueInstance().$mount("#app");
}

init();
