













































import Component from "vue-class-component";
import BaseComponent from "@/components/BaseComponent";

@Component
export default class ScoreUticaIcon extends BaseComponent {
  
}
