import { Mobile } from "./mobile.class";

export class Subscription {
    organisationId: number;
    subscriptions: Array<Mobile>;

    constructor(s?: Subscription)
    { 
        this.organisationId = s?.organisationId ?? 0;
        this.subscriptions = s?.subscriptions ?? new Array<Mobile>();
    }
}
