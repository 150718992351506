export class FiberAvailability {
  connection?: string;
  speed?: string;
  availability?: string;
  remarks?: string;

  constructor(f?: FiberAvailability) {
    this.connection = f?.connection ?? undefined;
    this.speed = f?.speed ?? undefined;
    this.availability = f?.availability ?? undefined;
    this.remarks = f?.remarks ?? undefined;
  }
}
