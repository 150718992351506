





























import Component from "vue-class-component";
import Vue from "vue";

@Component({
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    showFooter: {
      default: true,
      type: Boolean,
    },
    closeOnBackdropClick: Boolean,
    showCloseButton: Boolean,
    headerTitle: String,
    slotData: {
      default: Object,
    },
    size: {
      type: String,
      default: "md",
    },
  },
})
export default class Modal extends Vue {
  showModal = false;

  get modalSize(): string {
    const variant = this.$props.size;
    if (variant === "sm") return "400px";
    if (variant === "md") return "800px";
    if (variant === "lg") return "1000px";

    return "800px";
  }

  mounted(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;

    setTimeout(() => {
      this.$emit("close");
    }, 500);
  }

  backdropClick(): void {
    if (this.$props.closeOnBackdropClick) this.closeModal();
  }

  emitChange(changeName: string, value: unknown): void {
    this.$emit("change", { changeName, value });
  }
}
