import { FiberAvailability } from "./fiberAvailability.class";

export class FiberResponseModel {
    fiberAvailability?: FiberAvailability;
    weasFiberAvailability?: FiberAvailability;
    weasKoperAvailability?: FiberAvailability;
  
    constructor(f?: FiberResponseModel) {
      this.fiberAvailability = f?.weasFiberAvailability ?? undefined;
      this.weasFiberAvailability = f?.weasFiberAvailability ?? undefined;
      this.weasKoperAvailability = f?.weasKoperAvailability ?? undefined;
    }
  }