
















































































































































import BaseComponent from "@/components/BaseComponent";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import { Component } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import "vue-select/dist/vue-select.css";
import { Subscription } from "@/types/subscription.class";
import { getSubscription } from "@/api/subscription";
import showNotification from "@/utils/notification";
import MyActions from "@/components/MyActions.vue";
import Simswap from "@/components/svgs/SimswapIcon.vue";
import Back from "@/components/svgs/BackIcon.vue";

@Component({
  components: {
    Modal,
    MyActions,
    Simswap,
    Back,
  },
})
export default class SimSwap extends BaseComponent {
  simSwapIsValid = false;
  showAddSimModal = false;
  showPhysical = false;
  showESim = false;
  subscriptions: Subscription = new Subscription();
  search = "";
  fields: any[] = [
    {
      key: "mobileNumber",
      label: "Mobiele nummer",
    },
    {
      key: "actions",
      label: "",
      thClass: "text-right",
      tdClass: "text-right",
    },
  ];
  async mounted(): Promise<void> {
    await this.getData();
  }

  simSwapUrl(mobile: any): string {
    return `https://onebase.io/mobile/${
      (this as any).$auth.claims["http://score-utica/irmanumber"]
    }/subscription/${mobile.item.orderId}`;
  }
  async getData(): Promise<void> {
    try {
      this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);
      this.subscriptions = (await getSubscription()).data.body;
    } catch (error) {
      showNotification(
        "Er ging iets mis met het ophalen van de data. Probeer het later nog eens."
      );
    } finally {
      this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
    }
  }

  get filterList(): any {
    return this.subscriptions.subscriptions.filter((simswap) => {
      return simswap.mobileNumber.toString()?.includes(this.search);
    });
  }
}
