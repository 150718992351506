export class FiberSupplier {
  supplier?: string;
  portal?: boolean;
  widget?: boolean;

  constructor(f?: FiberSupplier) {
    this.supplier = f?.supplier ?? '';
    this.portal = f?.portal ?? false;
    this.widget = f?.widget ?? false;
  }
}

export class FiberSupplierData {
  suppliers?: string[];
  portal?: string[];
  widget?: string[];
  both?: string[];

  constructor(s?: FiberSupplierData) {
    this.suppliers = s?.suppliers ?? [];
    this.portal = s?.portal ?? [];
    this.widget = s?.widget ?? [];
    this.both = s?.both ?? [];
  }
}