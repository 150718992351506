import { miscTypes } from "@/store/modules/misc/miscTypes";
import { IState } from "@/store/state.interface";
import IHeaderNavLink from "@/types/headerNavLink.interface";
import { getInstance } from "@/utils/auth";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class BaseComponent extends Vue {
  JSON = JSON;

  get $state(): IState {
    return this.$store.state as IState;
  }

  clog(val: any): void {
    console.log(val);
  }

  isLoggedIn(): boolean {
    return getInstance().isAuthenticated;
  }

  protected _genericInit(
    clearNavLinks: boolean,
    toKeepNavLabels?: string[]
  ): void {
    if (clearNavLinks) {
      this.$store.state.dispatch(miscTypes.actions.REMOVE_HEADER_NAV_LINKS);
    }

    if (toKeepNavLabels) {
      const links = this.$store.state.misc.headerNavLinks;

      links.forEach((l: IHeaderNavLink) => {
        if (!toKeepNavLabels.includes(l.label)) {
          this.$store.dispatch(
            miscTypes.actions.REMOVE_HEADER_NAV_LINK_BY_LABEL,
            l.label
          );
        }
      });
    }
  }
}
