import { SwapRequest } from "@/types/swapRequest.class";
import getEnv from "@/utils/environment/getEnvironment";
import axios, { AxiosResponse } from "axios";

export function getSubscription(): Promise<AxiosResponse> {
    return axios.get(`${getEnv()?.apiBaseUrl}/v1/subscription`);
  }

  export function getSimSwaps(): Promise<AxiosResponse> {
    return axios.get(`${getEnv()?.apiBaseUrl}/v1/subscription/simswap-requests`);
  }

  export function postSimswap(swapRequest: SwapRequest): Promise<AxiosResponse> {
    return axios.post(`${getEnv()?.apiBaseUrl}/v1/subscription/swap`, swapRequest);
  }