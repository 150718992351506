












import Component from "vue-class-component";
import BaseComponent from "@/components/BaseComponent";

@Component({
  props: {
    show: Boolean,
    notificationText: String,
  },
})
export default class Notification extends BaseComponent {}
