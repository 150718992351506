import { EnvironmentState } from '@/store/modules/environment/environmentModule.class';
import axios from 'axios';

export default async function LoadEnvironment(): Promise<EnvironmentState> {
    const envConfig = await axios.get(`${window.location.origin}/environments/env.json`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await envConfig.data;
}