







import Component from "vue-class-component";
import BaseComponent from "@/components/BaseComponent";

@Component({
  props: {
    fill: String,
    height: String,
    width: String
  },
})
export default class Bell extends BaseComponent {
  
}
