import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Root from "@/views/Root.vue";
import AuthCallback from "@/views/Callback.vue";
import Users from "@/views/Users.vue";
import FiberAvailability from "@/views/FiberAvailability.vue";
import FiberSuppliers from "@/views/FiberSuppliers.vue";
import authGuard from "@/utils/auth/authRouteGuard";
import SimSwap from "@/views/SimSwap.vue";
import Tickets from "@/views/Tickets.vue";
import Contracts from "@/views/Contracts.vue";
import ContractDetails from "@/views/ContractDetails.vue";
import RoleEnum from "@/types/role.enum";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Root",
    component: Root,
    beforeEnter: authGuard,
  },
  {
    path: "/Tickets",
    name: "Tickets",
    component: Tickets,
    beforeEnter: authGuard,
  },
  {
    path: "/Users",
    name: "Users",
    component: Users,
    beforeEnter: authGuard,
    meta: {
      allowedRoles: [RoleEnum.Admin]
    }
  },
  {
    path: "/FiberAvailability",
    name: "FiberAvailability",
    component: FiberAvailability,
    beforeEnter: authGuard,
  },
  {
    path: "/SimSwap",
    name: "SimSwap",
    component: SimSwap,
    beforeEnter: authGuard,
  },
  {
    path: "/Contracts",
    name: "Contracts",
    component: Contracts,
    beforeEnter: authGuard,
  },
  {
    path: "/Contractdetails",
    name: "ContractDetails",
    component: ContractDetails,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/Management",
    name: "Management",
    component: FiberSuppliers,
    beforeEnter: authGuard,
    meta: {
      allowedRoles: [RoleEnum.Admin]
    }
  },
  {
    path: "/auth/callback",
    name: "Callback",
    component: AuthCallback,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
