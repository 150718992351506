import { User } from "@/types/user.class";
import getEnv from "@/utils/environment/getEnvironment";
import axios, { AxiosResponse } from "axios";

export function postAccount(user: User): Promise<AxiosResponse> {
  return axios.post(`${getEnv()?.apiBaseUrl}/v1/account`, user);
}

export function getOrganisations(): Promise<AxiosResponse> {
  return axios.get(`${getEnv()?.apiBaseUrl}/v1/account/organisations`);
}

export function updateUser(user: User): Promise<AxiosResponse> {
  return axios.post(`${getEnv()?.apiBaseUrl}/v1/account/update-user`, user);
}

export function getOrganisation(): Promise<AxiosResponse> {
  return axios.get(`${getEnv()?.apiBaseUrl}/v1/account/user-organisation`);
}

export function getAccounts(email: string, oranganisationId?: string): Promise<AxiosResponse> {
  if (oranganisationId == undefined) {
    return axios.get(`${getEnv()?.apiBaseUrl}/v1/account?search=${email}`);
  }
  return axios.get(`${getEnv()?.apiBaseUrl}/v1/account?search=${email}&organisationId=${oranganisationId}`);
}
