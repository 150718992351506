import { MutationTree } from 'vuex';
import { EnvironmentState } from './environmentModule.class';

const mutations: MutationTree<any> = {
    SET_ENVIRONMENT: (state: EnvironmentState, payload: any) => {
        const payloadKeys = Object.keys(payload);
        const stateKeys = Object.keys(state);

        const toReplaceValues = payloadKeys.filter((k: string) => stateKeys.includes(k));
        toReplaceValues.forEach((k: string) => {
            (state as any)[k] = payload[k]; 
        });
    },
};

export default mutations;
