

































import BaseComponent from "@/components/BaseComponent";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import { Component } from "vue-property-decorator";
import { IrmaOrder } from "@/types/irmaOrder.class";
import Modal from "@/components/Modal.vue";
import { getContracts } from "@/api/irma";
import "vue-select/dist/vue-select.css";
import { Contract } from "@/types/contract.class";
import MyActions from '@/components/MyActions.vue';
import Handshake from '@/components/svgs/HandshakeIcon.vue';
import Back from '@/components/svgs/BackIcon.vue';

@Component({
  components: {
    Modal,
    MyActions,
    Handshake,
    Back
  },
})
export default class Users extends BaseComponent {
  data: IrmaOrder[] = [];
  dataContract: Contract[] = [];
  allOpenRows: any = [];
  search = "";
  
  fieldsHeader: any[] = [
    {
      key: "contractTypeString",
      label: "Contracttype",
    },
    {
      key: "formattedDate",
      label: "Einddatum",
      sortable: true,
    },
    {
      key: "actions",
      label: "",
      thClass: "text-right",
      tdClass: "text-right",
      thStyle: { width: '5%' }
    },
  ];

  async mounted(): Promise<void> {
    await this.getData();
  }

  async getData(): Promise<void> {
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);
    const result = (await getContracts()).data.body.contracts;
    result.forEach((item: Contract) => {
      this.dataContract.push(new Contract(item));
    });
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
  }

  showDetails(item: Contract, index: number) {
    this.$router.push({
      name: "ContractDetails",
      params: {
        model: JSON.stringify(item),
      },
    });
  }
}
