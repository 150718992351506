import RoleEnum from '@/types/role.enum';
import { getInstance } from '.';

export default function authGuard(to: any, from: any, next: any) {
    const authService = getInstance();

    const fn = () => {
        let roleCheckPassed = false;
        const isAuthenticated = authService.isAuthenticated;
        const allowedRoles = to.meta?.allowedRoles ?? [];
        if (allowedRoles.length > 0) {
            const role: RoleEnum = authService.role;
            roleCheckPassed = allowedRoles.some((x: RoleEnum) => x === role);

        } else {
            roleCheckPassed = true;
        }

        // If the user is authenticated, continue with the route
        if (isAuthenticated && roleCheckPassed) {
            next();
            return;
        } else if (isAuthenticated && !roleCheckPassed) {
            // 403 pagina? of redirect
            next({ name: 'Home' })
            return;
        }

        // Otherwise, log in
        next({ name: 'AuthLogin' });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', (loading: boolean) => {
        if (loading === false) {
            return fn();
        }
    });
}
