
















import Component from "vue-class-component";
import BaseComponent from "@/components/BaseComponent";
import Bell from '@/components/svgs/BellIcon.vue';

@Component({
  components: {
    Bell
  },
})
export default class NotificationBell extends BaseComponent {
  showNotification = false;
}
