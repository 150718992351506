








































































import Component from "vue-class-component";
import BaseComponent from "@/components/BaseComponent";
import Chevron from '@/components/svgs/ChevronIcon.vue';
import Clipboard from '@/components/svgs/ClipboardIcon.vue';
import Users from '@/components/svgs/UsersIcon.vue';
import Simswap from '@/components/svgs/SimswapIcon.vue';
import Headset from '@/components/svgs/HeadsetIcon.vue';
import Landmark from '@/components/svgs/LandmarkIcon.vue';
import Handshake from '@/components/svgs/HandshakeIcon.vue';
import Microsoft from '@/components/svgs/MicrosoftIcon.vue';
import PhoneCenter from '@/components/svgs/PhoneCenterIcon.vue';
import Kpn from '@/components/svgs/KpnIcon.vue';
import getEnv from "@/utils/environment/getEnvironment";

@Component({
  components: {
    Chevron,
    Clipboard,
    Users,
    Simswap,
    Headset,
    Landmark,
    Handshake,
    Microsoft,
    PhoneCenter,
    Kpn
  }
})
export default class MyActions extends BaseComponent {
  microsoftOneBaseUrl(): string {
    return "https://onebase.io/mijn-bedrijf/microsoft365"
  }

  phoneOneBaseUrl(): string {
    return "https://onebase.io/mijn-bedrijf/telefooncentrale/demosct/overzicht"
  }
}
