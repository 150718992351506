import { IState } from "@/store/state.interface";
import IHeaderNavLink from "@/types/headerNavLink.interface";
import { Module } from "vuex";
import { BaseStoreModule } from "../baseStoreModule.class";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export class MiscStateModule extends BaseStoreModule<MiscState, IState> {
  constructor(state?: MiscState) {
    super({
      namespaced: true,
      state: new MiscState(state),
      actions,
      mutations,
      getters,
    });
  }

  getModule(): Module<MiscState, IState> {
    return {
      namespaced: this.namespaced,
      state: this.state,
      actions: this.actions,
      mutations: this.mutations,
      getters: this.getters,
    };
  }
}

export class MiscState {
  headerNavLinks: IHeaderNavLink[];
  showLoader: boolean;

  constructor(state?: MiscState) {
    this.headerNavLinks = state?.headerNavLinks ?? [];
    this.showLoader = false;
  }
}
