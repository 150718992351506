

























































































































































































































































import BaseComponent from "@/components/BaseComponent";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import { Component } from "vue-property-decorator";
import { User } from "@/types/user.class";
import Modal from "@/components/Modal.vue";
import {
  getAccounts,
  postAccount,
  getOrganisations,
  updateUser,
} from "@/api/user";
import showNotification from "@/utils/notification";
import { Organisation } from "@/types/organisation.class";
import "vue-select/dist/vue-select.css";
import MyActions from "@/components/MyActions.vue";
import UsersIcon from "@/components/svgs/UsersIcon.vue";
import Back from "@/components/svgs/BackIcon.vue";
import ToastrHelper from "@/utils/toastr-helper";

@Component({
  components: {
    Modal,
    MyActions,
    UsersIcon,
    Back,
  },
})
export default class Users extends BaseComponent {
  userIsValid = false;
  editUserIsValid = false;
  newUser: User = new User();
  editUser: User = new User();
  showNewUserModal = false;
  showEditUserModal = false;
  addUserDisabled = true;
  data: User[] = [];
  search = "";
  searchOrganisation = "";
  organisations: Organisation[] = [];
  showNoResults = false;
  roles = [
    { name: "User", value: 0 },
    { name: "Admin", value: 1 },
  ];
  fields: any[] = [
    {
      key: "email",
      label: "Email",
    },
    {
      key: "givenName",
      label: "Voornaam",
    },
    {
      key: "familyName",
      label: "Achternaam",
    },
    {
      key: "roleName",
      label: "Accounttype",
      sortable: true,
    },
    {
      key: "organisationName",
      label: "Organisatie",
      sortable: true,
    },
  ];
  async mounted(): Promise<void> {
    await this.getData();
    this.$watch(
      () => this.newUser,
      (n) => {
        this.userIsValid = this.newUser.isValid();
      },
      { deep: true }
    );

    this.$watch(
      () => this.editUser,
      (n) => {
        this.editUserIsValid = this.editUser.isValidEdit();
      },
      { deep: true }
    );
  }

  showAddUserModal(): void {
    this.newUser = new User();
    this.showNewUserModal = true;
  }

  async getUsers(): Promise<void> {
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);
    this.showNoResults = false;
    this.data.splice(0, this.data.length);
    
    var organisationId = undefined;
    if (this.searchOrganisation != "") {
      organisationId = this.searchOrganisation;
    }
    
    const result = (await getAccounts(this.search, organisationId)).data;
    
    if (result.hasErrors) {
      ToastrHelper.warningToastr("Er zijn te veel resultaten gevonden. Specificeer de zoekopdracht.", this);
    } else {
      result.body.forEach((item: User) => {
        if (item.organisationId !== 0) {
          const org = this.organisations.find(
            (x: Organisation) => x.id === item.organisationId
          );
          item.organisationName = org?.name ?? "";
        }
        this.data.push(new User(item));
      });
    }

    if (this.data.length === 0) {
      this.showNoResults = true;
    }
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
  }

  async getData(): Promise<void> {
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);

    this.organisations = (await getOrganisations()).data.body;

    // await this.getUsers();

    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
  }

  get filterList(): any {
    return this.data.filter((user) => {
      return (
        user.givenName?.toLowerCase().includes(this.search) ||
        user.familyName?.toLowerCase().includes(this.search) ||
        user.email?.toLowerCase().includes(this.search)
      );
    });
  }

  async postUser(): Promise<void> {
    if (!this.newUser.isValid()) {
      showNotification(
        "De ingevulde gegevens lijken onjuist / onvolledig, controleer de velden."
      );
      return;
    }

    this.$store.dispatch(miscTypes.actions.TOGGLE_LOADER);

    try {

      await postAccount(this.newUser);
      showNotification("Gebruiker is succesvol aangemaakt.");
      this.data.splice(0, this.data.length);
      await this.getUsers();
      this.showNewUserModal = false;
    } catch (error) {
      showNotification("Er ging iets mis met het aanmaken van een nieuwe gebruiker. Probeer het later nog eens.");
    } finally {
      this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
    }
  }

  async updateUser(): Promise<void> {
    try {
      await updateUser(this.editUser);
      this.data.splice(0, this.data.length);
      showNotification("Gebruiker is succesvol geupdated.");
      await this.getUsers();
      this.showEditUserModal = false;
    } catch (error) {
      showNotification("Er ging iets mis met het update van de gebruiker. Probeer het later nog eens");
    }
  }

  showDetails(item: User, index: number) {
    this.editUser = item;
    this.showEditUserModal = true;
  }
}
