import { FiberSupplierData } from "@/types/fiberSupplier.class";
import getEnv from "@/utils/environment/getEnvironment";
import axios, { AxiosResponse } from "axios";

export function postFiberSupplier(data: FiberSupplierData): Promise<AxiosResponse> {
  return axios.post(`${getEnv()?.apiBaseUrl}/v1/admin/fibersupplierwhitelist`, data);
}

export function getFiberSupplier(): Promise<AxiosResponse> {
  return axios.get(`${getEnv()?.apiBaseUrl}/v1/admin/fibersupplierwhitelist`);
}
