import RoleEnum from "./role.enum";

export class User {
  email: string;
  givenName: string;
  familyName: string;
  branchId: string;
  role: number;
  roleName: string;
  organisationId: number;
  organisationName: string;

  constructor(u?: User) {
    this.email = u?.email ?? "";
    this.givenName = u?.givenName ?? "";
    this.familyName = u?.familyName ?? "";
    this.branchId = u?.branchId ?? "";
    this.role = u?.role ?? 0;
    this.organisationId = u?.organisationId ?? 0;
    this.organisationName = u?.organisationName ?? "";

    switch (this.role) {
      case 0:
        this.roleName = RoleEnum.User;
        break;
      case 1:
        this.roleName = RoleEnum.Admin;
        break;
      default:
        this.roleName = RoleEnum.User;
        break;
    }
  }

  isValid(): boolean {
    if (
      !this.email ||
      !this.givenName ||
      !this.familyName ||
      !this.branchId ||
      (!this.role && this.role !== 0)
    ) {
      return false;
    }

    return true;
  }


  isValidEdit(): boolean {
    debugger;
    if (
      !this.email ||
      !this.givenName ||
      !this.familyName ||
      (!this.role && this.role !== 0)
    ) {
      return false;
    }

    return true;
  }
}
