import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export class BaseStoreModule<S, R> implements Module<S, R> {
  namespaced: boolean;
  state: S;
  actions: ActionTree<S, R> = {};
  mutations: MutationTree<S> = {};
  getters: GetterTree<S, R> = {};

  constructor(module: BaseStoreModule<S, R>) {
    this.namespaced = module.namespaced;
    this.state = module.state;
    this.actions = module.actions;
    this.mutations = module.mutations;
    this.getters = module.getters;
  }
}
