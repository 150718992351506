import { dateFormat, dayjsEx } from "@/utils/dayjs";
import { IrmaOrder } from "./irmaOrder.class";

export class Contract {
    contractType: number;
    contractTypeString: string;
    contractEndDate?: string;
    irmaOrders: Array<IrmaOrder>;
    constructor(o?: Contract)
    { 
        this.contractType = o ? o.contractType : 0;
        this.contractTypeString = o?.contractTypeString ?? "";
        this.contractEndDate = o?.contractEndDate ?? undefined;
        this.irmaOrders = o?.irmaOrders ?? new Array<IrmaOrder>();
    }

    get formattedDate(): string {
        if (!this.contractEndDate) {
            return 'Onbekend';
        }
        return dayjsEx(this.contractEndDate).utc(true).format(dateFormat);
    }
}
