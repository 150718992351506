import { IState } from '@/store/state.interface';
import { ActionContext, ActionTree } from 'vuex';
import { EnvironmentState } from './environmentModule.class';
import { environmentTypes } from './environmentTypes';

const actions: ActionTree<any, any> = {
    CHANGE_ENVIRONMENT: async (
        context: ActionContext<EnvironmentState, IState>,
        data: any
    ) => {
        context.commit(
            environmentTypes.mutations.SET_ENVIRONMENT,
            data,
            { root: true }
        );
    },
};

export default actions;
