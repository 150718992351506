


































import {
  getOrganisation
}from "@/api/user";
import Component from "vue-class-component";
import { getInstance } from "@/utils/auth";
import Vue from "vue";
import IHeaderNavLink from "@/types/headerNavLink.interface";
import Home from '@/components/svgs/HomeIcon.vue';
import NotificationBell from '@/components/NotificationBell.vue'
import ScoreUticaIcon from '@/components/svgs/ScoreUticaIcon.vue'
import { Organisation } from "@/types/organisation.class";

@Component({
  components: {
    Home,
    NotificationBell,
    ScoreUticaIcon
  },
  props: {
    navItems: {
      default: Array,
      type: Array,
    },
  },
})
export default class PageHeader extends Vue {

  organisationName = "";
  get title(): string {
    return this.$props.pageName ?? "Page Title Placeholder";
  }

  get navLinks(): IHeaderNavLink[] {
    return this.$props.navItems as IHeaderNavLink[];
  }

  navItemActiveBarStyles: { width: string; left: string } = {
    width: "0",
    left: "0",
  };

  async mounted(): Promise<void>  {
    this.$nextTick(this.calculateActiveNavItemBarPosition);

    this.$watch(
      () => this.navLinks,
      () => {
        this.$nextTick(() => this.calculateActiveNavItemBarPosition());
      }
    );

        const result = (await getOrganisation()).data.body;
        console.log(result);
        if (result !== null)
        {
          this.organisationName = result;
        }
  }

  logout(): void {
    getInstance().logout();
  }

  toggleNavItemActive(navItemIndex: number): void {
    const links: IHeaderNavLink[] = [...this.navLinks];

    links.forEach((item: any) => (item.active = false));
    links[navItemIndex].active = true;

    this.$emit("toggledNavLink", links);

    this.$nextTick(this.calculateActiveNavItemBarPosition);
  }

  calculateActiveNavItemBarPosition(): void {
    const activeNavItem = document.querySelector(
      ".nav-wrapper .nav-item.active .nav-item-label"
    );
    const defaultValue = (this.navItemActiveBarStyles = {
      width: "0",
      left: "0",
    });

    if (!activeNavItem) {
      this.navItemActiveBarStyles = defaultValue;
      return;
    }

    try {
      const textPositions = activeNavItem.getClientRects()[0];
      const extraBarWidth = 12;
      const leftPosition = Math.ceil(textPositions.left) - extraBarWidth / 2;

      this.navItemActiveBarStyles = {
        width: `${textPositions.width + extraBarWidth}px`,
        left: `${leftPosition}px`,
      };
    } catch {
      this.navItemActiveBarStyles = defaultValue;
    }
  }
}
