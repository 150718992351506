















import Component from "vue-class-component";
import PageHeader from "./components/PageHeader.vue";
import IHeaderNavLink from "./types/headerNavLink.interface";
import { miscTypes } from "./store/modules/misc/miscTypes";
import Loader from "@/components/Loader.vue";
import BaseComponent from "./components/BaseComponent";
import Notification from "@/components/Notification.vue";
import { EventBus } from "./utils/eventBus/eventBus";
import { EventBusEvent } from "./utils/eventBus/eventBusEvent.enum";

@Component({
  components: {
    PageHeader,
    Loader,
    Notification,
  },
})
export default class App extends BaseComponent {
  notificationText = "";
  showNotification = false;

  get headerNavLinks(): IHeaderNavLink {
    return this.$store.state.misc.headerNavLinks.sort(
      (a: IHeaderNavLink, b: IHeaderNavLink) => a.order - b.order
    );
  }

  mounted(): void {
    EventBus.$on(EventBusEvent.ShowNotification, (data: any) => {
      this._handleNotificationEvent(data);
    });
  }

  logout(): void {
    (this as any).$auth.logout();
  }

  toggledNavLinkListener(navLinks: IHeaderNavLink[]): void {
    const activeNavLink = navLinks.filter((n: IHeaderNavLink) => n.active);

    if (activeNavLink.length > 0) {
      if (activeNavLink[0].navPath) {
        this.$router.push({ path: activeNavLink[0].navPath }).catch(()=>{var a;});
      }
    }

    this.$store.commit(miscTypes.mutations.SET_HEADER_NAV_LINKS, navLinks);
  }

  private _handleNotificationEvent(eventData: {
    notificationText: string;
    timeout: number;
  }): void {
    this.notificationText = eventData.notificationText;
    this.showNotification = true;

    setTimeout(() => {
      this.showNotification = false;
    }, eventData.timeout);
  }
}
