









































import BaseComponent from "@/components/BaseComponent";
import { miscTypes } from "@/store/modules/misc/miscTypes";
import IHeaderNavLink from "@/types/headerNavLink.interface";
import { Component } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import { getFiberSupplier, postFiberSupplier } from "@/api/fiberSupplier";
import showNotification from "@/utils/notification";
import { FiberSupplier, FiberSupplierData } from "@/types/fiberSupplier.class";
import Manage from '@/components/svgs/ManageIcon.vue';
import Back from "@/components/svgs/BackIcon.vue";

@Component({
  components: {
    Modal,
    Manage,
    Back
  },
})
export default class FiberSuppliers extends BaseComponent {
  fiberSuppliers: FiberSupplier = new FiberSupplier();
  data: FiberSupplier[] = [];

  async mounted(): Promise<void> {
    this._genericInit(false, [
      "Tickets", 
      "Users", 
      "Fiber Availability", 
      "Sim Swap",
      "Contracts",
      "Management",
      ]);

    await this.getFiberSuppliers();

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Tickets"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Tickets",
        active: false,
        navPath: "/",
        order: 0,
      });
    }

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Users"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Users",
        active: false,
        navPath: "/users",
        order: 1,
      });
    }

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Fiber Availability"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Fiber Availability",
        active: false,
        navPath: "/fiberavailability",
        order: 2,
      });
    }

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Sim Swap"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Sim Swap",
        active: false,
        navPath: "/simswap",
        order: 3,
      });
    }

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Contracts"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Contracts",
        active: false,
        navPath: "/contracts",
        order: 4,
      });
    }

    if (
      this.$state.misc.headerNavLinks.filter(
        (l: IHeaderNavLink) => l.label === "Management"
      ).length === 0
    ) {
      this.$store.dispatch(miscTypes.actions.ADD_HEADER_NAV_LINK, {
        label: "Management",
        active: true,
        navPath: "/management",
        order: 5,
      });
    }
  }

  async getFiberSuppliers(): Promise<void> {
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, true);

    const result = (await getFiberSupplier()).data.body;

    this.data = [];

    result.suppliers?.forEach((item: string) => {
      this.data.push({
        'supplier': item,
        'portal': result.portal.includes(item) || result.both.includes(item),
        'widget': result.widget.includes(item) || result.both.includes(item),
      });
    });
    
    this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
  }

  async postFiberSuppliers(): Promise<void> {
    const fiberSupplierData: FiberSupplierData = new FiberSupplierData()

    this.$store.dispatch(miscTypes.actions.TOGGLE_LOADER);

    this.data.forEach(item => {
      let supplier : string = item.supplier || '';
      
      delete fiberSupplierData.suppliers;

      if (item.portal && item.widget) {
        fiberSupplierData.both?.push(supplier);
      }
      else if (item.portal) {
        fiberSupplierData.portal?.push(supplier);
      }
      else if (item.widget) {
        fiberSupplierData.widget?.push(supplier);
      }
    });

    try {
      await postFiberSupplier(fiberSupplierData);
      showNotification("Succesvol opgeslagen.");
      await this.getFiberSuppliers();
    } catch (error) {
      console.log("error");
      showNotification("Er ging iets mis. Probeer het later nog eens.");
    } finally {
      this.$store.commit(miscTypes.mutations.SET_SHOW_LOADER, false);
    }
  }
}
