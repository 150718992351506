




































































import Component from "vue-class-component";
import BaseComponent from "./BaseComponent";

@Component
export default class Loader extends BaseComponent {}
