import { IState } from "@/store/state.interface";
import IHeaderNavLink from "@/types/headerNavLink.interface";
import { ActionContext, ActionTree } from "vuex";
import { MiscState } from "./miscModule.class";
import { miscTypes } from "./miscTypes";

const actions: ActionTree<MiscState, IState> = {
  ADD_HEADER_NAV_LINK: (context: any, data: IHeaderNavLink) => {
    context.commit(
      miscTypes.mutations.SET_HEADER_NAV_LINKS,
      [...context.state.headerNavLinks, data],
      { root: true }
    );
  },
  REMOVE_HEADER_NAV_LINKS: (context: any) => {
    context.commit(miscTypes.mutations.SET_HEADER_NAV_LINKS, [], {
      root: true,
    });
  },
  REMOVE_HEADER_NAV_LINK_BY_LABEL: (
    context: ActionContext<MiscState, IState>,
    payload: string
  ) => {
    const links = context.state.headerNavLinks;
    context.commit(
      miscTypes.mutations.SET_HEADER_NAV_LINKS,
      links.filter((l: IHeaderNavLink) => l.label !== payload),
      { root: true }
    );
  },
  TOGGLE_LOADER: (context: ActionContext<MiscState, IState>) => {
    context.commit(
      miscTypes.mutations.SET_SHOW_LOADER,
      !context.state.showLoader,
      { root: true }
    );
  },
};

export default actions;
