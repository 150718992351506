import IHeaderNavLink from "@/types/headerNavLink.interface";
import { MutationTree } from "vuex";
import { MiscState } from "./miscModule.class";

const mutations: MutationTree<any> = {
  SET_HEADER_NAV_LINKS: (state: MiscState, payload: IHeaderNavLink[]) =>
    (state.headerNavLinks = [...payload]),
  SET_SHOW_LOADER: (state: MiscState, payload: boolean) =>
    (state.showLoader = payload),
};

export default mutations;
